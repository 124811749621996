import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconWarning = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M22.5689 1.43108L1.5505 22.4495C0.694186 23.3058 0.694186 24.6942 1.5505 25.5505L22.5689 46.5689C23.4252 47.4252 24.8136 47.4252 25.6699 46.5689L46.6883 25.5505C47.5447 24.6942 47.5447 23.3058 46.6883 22.4495L25.6699 1.43108C24.8136 0.574771 23.4252 0.574771 22.5689 1.43108Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24 28.22V14.22" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="24" cy="33" r="1" />
    </BaseIcon>
);

export default IconWarning;
