import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconNotebook = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M40.356 14.8C42.8965 14.8 44.956 12.7405 44.956 10.2C44.956 7.6595 42.8965 5.60001 40.356 5.60001C37.8155 5.60001 35.756 7.6595 35.756 10.2C35.756 12.7405 37.8155 14.8 40.356 14.8Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.356 27.066C42.8965 27.066 44.956 25.0065 44.956 22.466C44.956 19.9255 42.8965 17.866 40.356 17.866C37.8155 17.866 35.756 19.9255 35.756 22.466C35.756 25.0065 37.8155 27.066 40.356 27.066Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.356 5.6V2.534" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.356 30.134V27.066" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.08801 1H24.51C25.047 0.999738 25.5788 1.10528 26.075 1.31061C26.5712 1.51593 27.0221 1.81701 27.402 2.19664C27.7818 2.57627 28.0831 3.02702 28.2886 3.52313C28.4942 4.01924 28.6 4.55099 28.6 5.088V30.644C28.6 31.1808 28.4943 31.7124 28.2888 32.2084C28.0834 32.7044 27.7823 33.155 27.4027 33.5347C27.0231 33.9143 26.5724 34.2154 26.0764 34.4208C25.5804 34.6263 25.0488 34.732 24.512 34.732H5.08801C4.5459 34.732 4.026 34.5167 3.64268 34.1333C3.25936 33.75 3.04401 33.2301 3.04401 32.688V3.044C3.04401 2.5019 3.25936 1.982 3.64268 1.59867C4.026 1.21535 4.5459 1 5.08801 1V1Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.444 1V34.734" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.866 43.934C40.866 44.3366 40.7867 44.7353 40.6327 45.1073C40.4786 45.4793 40.2527 45.8173 39.968 46.102C39.6833 46.3867 39.3453 46.6125 38.9733 46.7666C38.6014 46.9207 38.2027 47 37.8 47H12.244L7.13403 43.934L12.244 40.866H37.8C38.2028 40.866 38.6017 40.9454 38.9738 41.0996C39.3459 41.2538 39.684 41.4798 39.9687 41.7647C40.2535 42.0496 40.4793 42.3879 40.6332 42.7601C40.7872 43.1323 40.8663 43.5312 40.866 43.934V43.934Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.2 41.378V46.488" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.09003 9.314H15.822" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.32 40.866V47" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.356 14.8V17.866" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconNotebook;
