import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconBlueprint = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M29 19.8L22 21.8L24 14.8L38.292 0.497996C38.3849 0.404869 38.4952 0.330984 38.6167 0.280571C38.7382 0.230158 38.8685 0.204208 39 0.204208C39.1315 0.204208 39.2618 0.230158 39.3833 0.280571C39.5048 0.330984 39.6151 0.404869 39.708 0.497996L43.292 4.084C43.4795 4.27152 43.5848 4.52583 43.5848 4.791C43.5848 5.05616 43.4795 5.31047 43.292 5.498L29 19.8Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35 3.79199L40 8.79199" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.996 14.794L28.996 19.794" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 9.8H46C46.2652 9.8 46.5196 9.90536 46.7071 10.0929C46.8946 10.2804 47 10.5348 47 10.8V44.8C47 45.0652 46.8946 45.3196 46.7071 45.5071C46.5196 45.6946 46.2652 45.8 46 45.8H2C1.73478 45.8 1.48043 45.6946 1.29289 45.5071C1.10536 45.3196 1 45.0652 1 44.8V10.8C1 10.5348 1.10536 10.2804 1.29289 10.0929C1.48043 9.90536 1.73478 9.8 2 9.8H24" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 15.8H8C7.73478 15.8 7.48043 15.9053 7.29289 16.0929C7.10536 16.2804 7 16.5348 7 16.8V38.8C7 39.0652 7.10536 39.3196 7.29289 39.5071C7.48043 39.6946 7.73478 39.8 8 39.8H25V32.8C25 32.5348 25.1054 32.2804 25.2929 32.0929C25.4804 31.9053 25.7348 31.8 26 31.8H34C34.2652 31.8 34.5196 31.9053 34.7071 32.0929C34.8946 32.2804 35 32.5348 35 32.8V39.8H40C40.2652 39.8 40.5196 39.6946 40.7071 39.5071C40.8946 39.3196 41 39.0652 41 38.8V16.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 35.794H35" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 39.794H35" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 25.794H16" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32 27.794H41" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32 23.794H41" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 31.8H14C14.2652 31.8 14.5196 31.9053 14.7071 32.0929C14.8946 32.2804 15 32.5348 15 32.8V39.8" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconBlueprint;
