import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconAnchor = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M20 5C20 6.06087 20.4214 7.07828 21.1716 7.82843C21.9217 8.57857 22.9391 9 24 9C25.0609 9 26.0783 8.57857 26.8284 7.82843C27.5786 7.07828 28 6.06087 28 5C28 3.93913 27.5786 2.92172 26.8284 2.17157C26.0783 1.42143 25.0609 1 24 1C22.9391 1 21.9217 1.42143 21.1716 2.17157C20.4214 2.92172 20 3.93913 20 5V5Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37 34L40.1 30.9C40.1727 30.827 40.2661 30.7783 40.3676 30.7604C40.469 30.7426 40.5735 30.7565 40.6667 30.8003C40.7599 30.844 40.8374 30.9155 40.8885 31.005C40.9395 31.0944 40.9617 31.1975 40.952 31.3C40.63 35.5756 38.7043 39.5716 35.5607 42.4874C32.417 45.4032 28.2877 47.0234 24 47.0234C19.7123 47.0234 15.583 45.4032 12.4393 42.4874C9.29571 39.5716 7.36998 35.5756 7.048 31.3C7.0383 31.1975 7.06049 31.0944 7.11155 31.005C7.16262 30.9155 7.24006 30.844 7.33329 30.8003C7.42652 30.7565 7.53099 30.7426 7.63243 30.7604C7.73386 30.7783 7.82731 30.827 7.9 30.9L11 34" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24 9V47" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 19H33" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconAnchor;
