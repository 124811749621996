import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconKitchen = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M1.27911 24.933H21.2791" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.2791 18.933H47.2791" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.2791 18.933L35.2791 14.933" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M39.2791 18.933L41.2791 14.933" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.27911 24.933H7.27911C6.74868 24.933 6.23997 24.7222 5.8649 24.3472C5.48983 23.9721 5.27911 23.4634 5.27911 22.933L5.27911 18.933H11.2791V22.933C11.2791 23.4634 11.0684 23.9721 10.6933 24.3472C10.3183 24.7222 9.80955 24.933 9.27911 24.933V24.933Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.2791 0.932953V8.93295" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.2791 8.93295V0.932953" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.27911 0.932953C2.74868 0.932953 2.23997 1.14367 1.8649 1.51874C1.48983 1.89381 1.27911 2.40252 1.27911 2.93295V8.93295H47.2791V2.93295C47.2791 2.40252 47.0684 1.89381 46.6933 1.51874C46.3183 1.14367 45.8095 0.932953 45.2791 0.932953H3.27911Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.2791 46.933V34.933" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.2791 34.933V46.933" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.2791 46.933C45.8095 46.933 46.3183 46.7222 46.6933 46.3472C47.0684 45.9721 47.2791 45.4634 47.2791 44.933V34.933H1.27911V44.933C1.27911 45.4634 1.48983 45.9721 1.8649 46.3472C2.23997 46.7222 2.74868 46.933 3.27911 46.933H45.2791Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.27911 30.933H45.2791C45.8095 30.933 46.3183 31.1437 46.6933 31.5187C47.0684 31.8938 47.2791 32.4025 47.2791 32.933V34.933H1.27911V32.933C1.27911 32.4025 1.48983 31.8938 1.8649 31.5187C2.23997 31.1437 2.74868 30.933 3.27911 30.933V30.933Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconKitchen;
