import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconWireframe = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M21.9135 36.9219H2.91345C2.38302 36.9219 1.87431 36.7112 1.49924 36.3361C1.12417 35.961 0.913452 35.4523 0.913452 34.9219V2.92188C0.913452 2.39145 1.12417 1.88274 1.49924 1.50767C1.87431 1.1326 2.38302 0.921883 2.91345 0.921883H32.9135C33.4439 0.921883 33.9526 1.1326 34.3277 1.50767C34.7027 1.88274 34.9135 2.39145 34.9135 2.92188V22.8519" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.9135 4.92188H5.91345C5.36117 4.92188 4.91345 5.3696 4.91345 5.92188V13.9219C4.91345 14.4742 5.36117 14.9219 5.91345 14.9219H29.9135C30.4657 14.9219 30.9135 14.4742 30.9135 13.9219V5.92188C30.9135 5.3696 30.4657 4.92188 29.9135 4.92188Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3135 38.5219L31.3135 44.5219M4.91345 19.9219V31.9219C4.91345 32.1871 5.01881 32.4415 5.20635 32.629C5.39388 32.8165 5.64824 32.9219 5.91345 32.9219H18.5875C18.7422 32.9219 18.8948 32.8861 19.0334 32.8171C19.1719 32.7481 19.2925 32.648 19.3858 32.5245C19.479 32.401 19.5423 32.2576 19.5707 32.1054C19.5991 31.9533 19.5919 31.7967 19.5495 31.6479L16.1215 19.6479C16.0619 19.4387 15.9357 19.2547 15.7621 19.1237C15.5885 18.9927 15.3769 18.9218 15.1595 18.9219H5.91345C5.64824 18.9219 5.39388 19.0272 5.20635 19.2148C5.01881 19.4023 4.91345 19.6567 4.91345 19.9219ZM30.9135 26.9219V19.9219C30.9135 19.6567 30.8081 19.4023 30.6206 19.2148C30.433 19.0272 30.1787 18.9219 29.9135 18.9219H22.2395C22.0847 18.9218 21.9321 18.9577 21.7935 19.0267C21.655 19.0956 21.5344 19.1958 21.4411 19.3193C21.3479 19.4428 21.2846 19.5862 21.2562 19.7383C21.2278 19.8904 21.2351 20.0471 21.2775 20.1959L24.9135 32.9219H25.8295L30.9135 26.9219ZM31.3135 44.5219L22.9135 46.9219L25.3135 38.5219L39.6715 24.1639C40.0654 23.7699 40.5331 23.4574 41.0479 23.2442C41.5626 23.031 42.1143 22.9212 42.6715 22.9212C43.2286 22.9212 43.7803 23.031 44.295 23.2442C44.8098 23.4574 45.2775 23.7699 45.6715 24.1639C46.0654 24.5578 46.3779 25.0256 46.5911 25.5403C46.8044 26.055 46.9141 26.6067 46.9141 27.1639C46.9141 27.721 46.8044 28.2727 46.5911 28.7875C46.3779 29.3022 46.0654 29.7699 45.6715 30.1639L31.3135 44.5219ZM37.8715 25.9639L43.8715 31.9639L37.8715 25.9639Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconWireframe;
