import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconExtension = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M0 44.1522H48" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0 27.4565L20.8696 19.1087" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.17389 25.8266V44.1522" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.7391 4.5L48 23.2826" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43.8261 20.7386V44.1522" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.5652 16.8276V9.71738C37.5652 9.44063 37.6752 9.17522 37.8709 8.97953C38.0666 8.78384 38.332 8.6739 38.6087 8.6739H40.6957C40.9724 8.6739 41.2378 8.78384 41.4335 8.97953C41.6292 9.17522 41.7392 9.44063 41.7392 9.71738V19.4029" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M38.6087 44.1522C38.8854 44.1522 39.1508 44.0422 39.3465 43.8465C39.5422 43.6509 39.6522 43.3854 39.6522 43.1087V36.8478C39.6522 36.5711 39.5422 36.3057 39.3465 36.11C39.1508 35.9143 38.8854 35.8044 38.6087 35.8044H26.0869C25.8102 35.8044 25.5448 35.9143 25.3491 36.11C25.1534 36.3057 25.0435 36.5711 25.0435 36.8478V43.1087C25.0435 43.3854 25.1534 43.6509 25.3491 43.8465C25.5448 44.0422 25.8102 44.1522 26.0869 44.1522" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.3913 35.8044V44.1522" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.1325 23.2826C27.1325 24.1129 27.4623 24.9091 28.0494 25.4962C28.6365 26.0832 29.4327 26.413 30.2629 26.413C31.0932 26.413 31.8894 26.0832 32.4765 25.4962C33.0636 24.9091 33.3934 24.1129 33.3934 23.2826C33.3934 22.4524 33.0636 21.6561 32.4765 21.0691C31.8894 20.482 31.0932 20.1522 30.2629 20.1522C29.4327 20.1522 28.6365 20.482 28.0494 21.0691C27.4623 21.6561 27.1325 22.4524 27.1325 23.2826V23.2826Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.6522 44.1522C15.9289 44.1522 16.1943 44.0422 16.39 43.8465C16.5857 43.6509 16.6957 43.3854 16.6957 43.1087V34.7609C16.6957 34.4841 16.5857 34.2187 16.39 34.023C16.1943 33.8273 15.9289 33.7174 15.6522 33.7174H9.39132C9.11457 33.7174 8.84916 33.8273 8.65347 34.023C8.45778 34.2187 8.34784 34.4841 8.34784 34.7609V43.1087C8.34784 43.3854 8.45778 43.6509 8.65347 43.8465C8.84916 44.0422 9.11457 44.1522 9.39132 44.1522" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.8696 6.48886V44.1522" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconExtension;
