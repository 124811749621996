import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconContract = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M40.4437 40.6163L32.5837 42.1963" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.4437 33.3163L29.7437 34.8363C29.1538 35.0764 28.4935 35.0764 27.9037 34.8363C27.4804 34.6582 27.117 34.3623 26.8568 33.9838C26.5966 33.6054 26.4505 33.1602 26.4357 32.7012C26.421 32.2421 26.5383 31.7885 26.7737 31.3941C27.0091 30.9998 27.3527 30.6812 27.7637 30.4763L31.4437 28.6763C31.9006 28.4382 32.4085 28.3146 32.9237 28.3163C33.3126 28.3144 33.6986 28.3822 34.0637 28.5163L40.8237 31.0963" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.4437 42.3163H21.2437L25.9037 45.8563C26.1354 46.1225 26.4629 46.2866 26.8149 46.3128C27.1669 46.339 27.515 46.2253 27.7837 45.9963L34.7837 40.3963C35.0557 40.1666 35.2283 39.8406 35.2656 39.4865C35.3029 39.1324 35.2019 38.7776 34.9837 38.4963L31.1437 34.2563" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.4437 30.6763L26.9437 30.2563C26.4424 29.8785 25.8314 29.6748 25.2037 29.6763C24.8327 29.6779 24.4657 29.7526 24.1237 29.8963L18.4437 32.3163" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.4437 44.3163H16.4437C16.9741 44.3163 17.4828 44.1056 17.8579 43.7305C18.233 43.3554 18.4437 42.8467 18.4437 42.3163V32.3163C18.4437 31.7859 18.233 31.2771 17.8579 30.9021C17.4828 30.527 16.9741 30.3163 16.4437 30.3163H12.4437" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.4437 44.3163H42.4437C41.9132 44.3163 41.4045 44.1056 41.0294 43.7305C40.6544 43.3554 40.4437 42.8467 40.4437 42.3163V32.3163C40.4437 31.7859 40.6544 31.2771 41.0294 30.9021C41.4045 30.527 41.9132 30.3163 42.4437 30.3163H46.4437" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.4437 0.316284H6.44366C4.85237 0.316284 3.32624 0.948425 2.20102 2.07364C1.07581 3.19886 0.443665 4.72499 0.443665 6.31628V45.3163C0.443665 45.5815 0.549021 45.8359 0.736558 46.0234C0.924094 46.2109 1.17845 46.3163 1.44366 46.3163H7.44366" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.44366 8.31628H22.4437" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.44366 14.3163H22.4437" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.44366 20.3163H13.4437" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.4437 25.3163V5.31628C28.4437 3.9902 28.9704 2.71843 29.9081 1.78075C30.8458 0.843068 32.1176 0.316284 33.4437 0.316284C36.2037 0.316284 38.4437 2.55628 38.4437 6.31628H28.4437" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconContract;
