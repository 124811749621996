import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconEarthHeart = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M35 47L24.78 36.34C23.8707 35.4458 23.27 34.2852 23.065 33.0265C22.86 31.7678 23.0614 30.4765 23.64 29.34V29.34C24.062 28.4767 24.6852 27.7274 25.4571 27.1552C26.229 26.5829 27.1271 26.2044 28.0757 26.0516C29.0244 25.8987 29.9959 25.9759 30.9085 26.2767C31.8211 26.5774 32.6481 27.093 33.32 27.78L35 29.44L36.68 27.78C37.3518 27.093 38.1789 26.5774 39.0915 26.2767C40.0041 25.9759 40.9756 25.8987 41.9242 26.0516C42.8729 26.2044 43.771 26.5829 44.5429 27.1552C45.3148 27.7274 45.938 28.4767 46.36 29.34C46.9386 30.4765 47.1399 31.7678 46.935 33.0265C46.73 34.2852 46.1293 35.4458 45.22 36.34L35 47Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 42.9C19.7803 43.3037 15.5373 42.421 11.8287 40.3679C8.12017 38.3147 5.11977 35.1874 3.22204 31.397C1.3243 27.6066 0.618079 23.3306 1.19623 19.1313C1.77438 14.9319 3.60983 11.0059 6.46125 7.86933C9.31266 4.73277 13.0465 2.53256 17.1719 1.55799C21.2973 0.58343 25.6211 0.880142 29.5746 2.40912C33.5282 3.93809 36.9265 6.62774 39.3228 10.1244C41.719 13.6211 43.0009 17.7611 43 22" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.32001 18.34H12.42C13.1447 18.3443 13.8612 18.4934 14.5275 18.7784C15.1938 19.0635 15.7965 19.4788 16.3 20L18.3 22C19.2939 22.9862 19.8709 24.3165 19.9119 25.7161C19.9528 27.1156 19.4545 28.4774 18.52 29.52L16 32.32C15.1264 33.3436 14.6632 34.6548 14.7 36V41.74" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.08 7.4H26.5C25.4126 7.4 24.3698 7.83196 23.6009 8.60086C22.832 9.36976 22.4 10.4126 22.4 11.5C22.4 12.5874 22.832 13.6302 23.6009 14.3991C24.3698 15.168 25.4126 15.6 26.5 15.6H27.28C28.1786 15.5955 29.0645 15.8121 29.8597 16.2306C30.6549 16.6491 31.335 17.2568 31.84 18L32.34 18.74" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconEarthHeart;
