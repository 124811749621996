import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconTaskList = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M31.1013 44.6016C31.1013 45.1321 30.8905 45.6408 30.5155 46.0158C30.1404 46.3909 29.6317 46.6016 29.1013 46.6016H9.10126C8.57082 46.6016 8.06212 46.3909 7.68704 46.0158C7.31197 45.6408 7.10126 45.1321 7.10126 44.6016V12.6016H31.1013V44.6016Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.10126 32.6016C2.57082 32.6016 2.06212 32.3909 1.68704 32.0158C1.31197 31.6408 1.10126 31.1321 1.10126 30.6016V6.60163C1.10126 5.41494 1.45315 4.2549 2.11244 3.2682C2.77173 2.28151 3.7088 1.51247 4.80516 1.05835C5.90151 0.604223 7.10791 0.485403 8.2718 0.716915C9.43569 0.948426 10.5048 1.51987 11.3439 2.35899C12.183 3.1981 12.7545 4.2672 12.986 5.43108C13.2175 6.59497 13.0987 7.80137 12.6445 8.89773C12.1904 9.99408 11.4214 10.9312 10.4347 11.5904C9.44798 12.2497 8.28795 12.6016 7.10126 12.6016" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.1013 12.6016C32.6926 12.6016 34.2187 11.9695 35.3439 10.8443C36.4691 9.71905 37.1013 8.19292 37.1013 6.60162C37.1013 5.01032 36.4691 3.4842 35.3439 2.35898C34.2187 1.23376 32.6926 0.601624 31.1013 0.601624H7.10126" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1013 20.6016H27.1013" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1013 28.6016H27.1013" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1013 36.6016H27.1013" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.1013 19.6016L12.1013 20.6016L16.1013 16.6016" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.1013 27.6016L12.1013 28.6016L16.1013 24.6016" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.1013 43.6016C47.1013 44.3973 46.7852 45.1603 46.2226 45.7229C45.66 46.2856 44.8969 46.6016 44.1013 46.6016C43.3056 46.6016 42.5425 46.2856 41.9799 45.7229C41.4173 45.1603 41.1013 44.3973 41.1013 43.6016V14.6016C41.1013 13.806 41.4173 13.0429 41.9799 12.4803C42.5425 11.9177 43.3056 11.6016 44.1013 11.6016C44.8969 11.6016 45.66 11.9177 46.2226 12.4803C46.7852 13.0429 47.1013 13.806 47.1013 14.6016V43.6016Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.1013 42.6016H47.1013" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.1013 28.6016H47.1013" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.1013 31.6016V16.6016C37.1013 15.806 37.4173 15.0429 37.9799 14.4803C38.5425 13.9177 39.3056 13.6016 40.1013 13.6016H41.2733" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconTaskList;
