import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconSignpost = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M23 33V47" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 1V5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 17V21" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.292 16.708C41.1046 16.8949 40.8507 16.9999 40.586 17H8C7.73478 17 7.48043 16.8946 7.29289 16.7071C7.10536 16.5196 7 16.2652 7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H40.586C40.8507 5.00006 41.1046 5.10506 41.292 5.292L46.292 10.292C46.3851 10.3849 46.459 10.4952 46.5094 10.6167C46.5598 10.7382 46.5858 10.8685 46.5858 11C46.5858 11.1315 46.5598 11.2618 46.5094 11.3833C46.459 11.5048 46.3851 11.6151 46.292 11.708L41.292 16.708Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.708 32.708C6.89541 32.8949 7.1493 32.9999 7.414 33H40C40.2652 33 40.5196 32.8946 40.7071 32.7071C40.8946 32.5196 41 32.2652 41 32V22C41 21.7348 40.8946 21.4804 40.7071 21.2929C40.5196 21.1054 40.2652 21 40 21H7.414C7.1493 21.0001 6.89541 21.1051 6.708 21.292L1.708 26.292C1.61488 26.3849 1.54099 26.4952 1.49058 26.6167C1.44016 26.7382 1.41422 26.8685 1.41422 27C1.41422 27.1315 1.44016 27.2618 1.49058 27.3833C1.54099 27.5048 1.61488 27.6151 1.708 27.708L6.708 32.708Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconSignpost;
