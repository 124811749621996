import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconQuestionPin = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M42 20.126C42 32.58 27.756 44.244 24.61 46.666C24.4352 46.8006 24.2206 46.8736 24 46.8736C23.7794 46.8736 23.5648 46.8006 23.39 46.666C20.242 44.242 6 32.58 6 20.126C6 9.63398 13.506 1.12598 24 1.12598C34.494 1.12598 42 9.63398 42 20.126Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 17.126C18.0002 16.0443 18.2927 14.9828 18.8468 14.0538C19.4008 13.1247 20.1958 12.3628 21.1474 11.8485C22.099 11.3342 23.172 11.0867 24.2526 11.1323C25.3334 11.1778 26.3818 11.5147 27.2868 12.1072C28.1916 12.6998 28.9196 13.526 29.3936 14.4983C29.8674 15.4707 30.0696 16.5531 29.9788 17.631C29.888 18.7088 29.5074 19.7421 28.8774 20.6214C28.2474 21.5008 27.3914 22.1934 26.4 22.626C25.6864 22.9376 25.0792 23.4504 24.6528 24.102C24.2266 24.7536 23.9996 25.5154 24 26.294V27.126" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 32.126C23.7238 32.126 23.5 31.9022 23.5 31.626C23.5 31.3498 23.7238 31.126 24 31.126" />
            <path d="M24 32.126C24.2762 32.126 24.5 31.9022 24.5 31.626C24.5 31.3498 24.2762 31.126 24 31.126" />
        </Fragment>
    </BaseIcon>
);

export default IconQuestionPin;
