import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconCalendar = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M11 1V11M37 1V11M1 15H47M11.998 21.998L14.998 24.998M14.998 24.998L17.998 27.998M14.998 24.998L17.998 21.998M14.998 24.998L11.998 27.998M34.996 31.998L37.996 34.998M37.996 34.998L40.996 37.998M37.996 34.998L40.996 31.998M37.996 34.998L34.996 37.998M22.998 24.998H26.998M32.998 24.998H36.998M7 34.996H11M16.998 34.996H20.998M26.998 34.996H30.998M3 5H45C46.1046 5 47 5.89543 47 7V45C47 46.1046 46.1046 47 45 47H3C1.89543 47 1 46.1046 1 45V7C1 5.89543 1.89543 5 3 5Z" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconCalendar;
