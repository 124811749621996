import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconBuildingChecklist = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M20.7948 18.9224V25.9224C20.7948 26.1876 20.6894 26.442 20.5019 26.6295C20.3144 26.817 20.06 26.9224 19.7948 26.9224H9.7948C9.52958 26.9224 9.27523 26.817 9.08769 26.6295C8.90016 26.442 8.7948 26.1876 8.7948 25.9224V18.9224" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.79077 17.9224L14.7908 8.92239L24.7908 17.9224" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.7948 10.9224L33.1028 13.5224C33.1887 13.6369 33.2982 13.7316 33.4238 13.8002C33.5495 13.8687 33.6884 13.9095 33.8312 13.9197C33.974 13.9299 34.1173 13.9094 34.2514 13.8595C34.3856 13.8096 34.5074 13.7315 34.6088 13.6304L40.7948 7.92239" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.7948 22.9224L33.1028 25.5224C33.1887 25.6369 33.2982 25.7316 33.4238 25.8002C33.5495 25.8687 33.6884 25.9095 33.8312 25.9197C33.974 25.9299 34.1173 25.9094 34.2514 25.8595C34.3856 25.8096 34.5074 25.7315 34.6088 25.6304L40.7948 19.9224" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.79077 32.9224H22.7908" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.79077 38.9224H22.7908" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.7948 34.0944C46.7947 34.6248 46.5839 35.1334 46.2088 35.5084L35.3808 46.3364C35.0058 46.7115 34.4972 46.9223 33.9668 46.9224H2.7948C2.26437 46.9224 1.75566 46.7117 1.38059 46.3366C1.00551 45.9615 0.7948 45.4528 0.7948 44.9224V2.92239C0.7948 2.39196 1.00551 1.88325 1.38059 1.50818C1.75566 1.13311 2.26437 0.922394 2.7948 0.922394H44.7948C45.3252 0.922394 45.8339 1.13311 46.209 1.50818C46.5841 1.88325 46.7948 2.39196 46.7948 2.92239V34.0944Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.6148 34.9224H36.7948C36.2644 34.9224 35.7557 35.1331 35.3806 35.5082C35.0055 35.8833 34.7948 36.392 34.7948 36.9224V46.7424" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconBuildingChecklist;
