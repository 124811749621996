import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconCommercial = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M15 47V39.16" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27 47V39.16" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 39H31" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 47V13C1 12.4696 1.21071 11.9609 1.58579 11.5858C1.96086 11.2107 2.46957 11 3 11H19C19.5304 11 20.0391 11.2107 20.4142 11.5858C20.7893 11.9609 21 12.4696 21 13V34" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 6C15 5.73478 14.8946 5.48043 14.7071 5.29289C14.5196 5.10536 14.2652 5 14 5H8C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6V11H15V6Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 5V1" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 17H17" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 23H17" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 29H17" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 47H45" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37 23V46.16" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37 29L41 26" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41 33H41.5C42.8338 32.9915 44.1191 32.4985 45.1165 31.6129C46.114 30.7273 46.7556 29.5095 46.9219 28.186C47.0883 26.8626 46.768 25.5238 46.0208 24.4189C45.2735 23.314 44.1502 22.5183 42.86 22.18C42.6787 21.2907 42.3132 20.4493 41.7869 19.7099C41.2607 18.9704 40.5855 18.3494 39.8048 17.8867C39.024 17.424 38.155 17.13 37.2537 17.0235C36.3524 16.917 35.4388 17.0005 34.5717 17.2684C33.7046 17.5364 32.9033 17.983 32.2192 18.5795C31.5352 19.1759 30.9836 19.909 30.6001 20.7315C30.2165 21.554 30.0095 22.4478 29.9922 23.3552C29.975 24.2626 30.148 25.1635 30.5 26C29.5717 26 28.6815 26.3687 28.0251 27.0251C27.3687 27.6815 27 28.5717 27 29.5C27 30.4283 27.3687 31.3185 28.0251 31.9749C28.6815 32.6312 29.5717 33 30.5 33H32" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37 31L34 29" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconCommercial;
