import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconPencils = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M19.3788 42.5557C19.3788 43.6165 18.9574 44.634 18.2073 45.3841C17.4571 46.1342 16.4397 46.5557 15.3788 46.5557C14.318 46.5557 13.3006 46.1342 12.5504 45.3841C11.8003 44.634 11.3788 43.6165 11.3788 42.5557V15.5557L14.9788 7.46967C15.2028 6.96767 15.5668 6.96767 15.7908 7.46967L19.3788 15.5557V42.5557Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.3788 40.5557H19.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.3788 36.5557H19.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.3788 0.555664H27.3788C26.2743 0.555664 25.3788 1.45109 25.3788 2.55566V44.5557C25.3788 45.6602 26.2743 46.5557 27.3788 46.5557H35.3788C36.4834 46.5557 37.3788 45.6602 37.3788 44.5557V2.55566C37.3788 1.45109 36.4834 0.555664 35.3788 0.555664Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3788 6.55566H29.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3788 14.5557H29.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3788 22.5557H29.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3788 30.5557H29.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.3788 38.5557H29.3788" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.8229 14.5557H18.9349" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.3788 14.5557V36.5557" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconPencils;
