import IconAnchor from '../../components/Icons/IconAnchor';
import IconArrowLeft from '../../components/Icons/IconArrowLeft';
import IconArrowRight from '../../components/Icons/IconArrowRight';
import IconBlueprint from '../../components/Icons/IconBlueprint';
import IconBudget from '../../components/Icons/IconBudget';
import IconBuildingChecklist from '../../components/Icons/IconBuildingChecklist';
import IconBusinessDeal from '../../components/Icons/IconBusinessDeal';
import IconCalendar from '../../components/Icons/IconCalendar';
import IconCommercial from '../../components/Icons/IconCommercial';
import IconContract from '../../components/Icons/IconContract';
import IconConversion from '../../components/Icons/IconConversion';
import IconCross from '../../components/Icons/IconCross';
import IconContractApproved from '../../components/Icons/IconContractApproved';
import IconEarthHeart from '../../components/Icons/IconEarthHeart';
import IconHelp from '../../components/Icons/IconHelp';
import IconExtension from '../../components/Icons/IconExtension';
import IconInfo from '../../components/Icons/IconInfo';
import IconKitchen from '../../components/Icons/IconKitchen';
import IconLeaf from '../../components/Icons/IconLeaf';
import IconMail from '../../components/Icons/IconMail';
import IconMapSearch from '../../components/Icons/IconMapSearch';
import IconMenu from '../../components/Icons/IconMenu';
import IconNewBuild from '../../components/Icons/IconNewBuild';
import IconNotebook from '../../components/Icons/IconNotebook';
import IconPencils from '../../components/Icons/IconPencils';
import IconPhone from '../../components/Icons/IconPhone';
import IconPlans from '../../components/Icons/IconPlans';
import IconPlay from '../../components/Icons/IconPlay';
import IconQuestionPin from '../../components/Icons/IconQuestionPin';
import IconQuote from '../../components/Icons/IconQuote';
import IconRealEstate from '../../components/Icons/IconRealEstate';
import IconRenovation from '../../components/Icons/IconRenovation';
import IconRibbon from '../../components/Icons/IconRibbon';
import IconSignpost from '../../components/Icons/IconSignpost';
import IconStairs from '../../components/Icons/IconStairs';
import IconTaskList from '../../components/Icons/IconTaskList';
import IconWarning from '../../components/Icons/IconWarning';
import IconWireframe from '../../components/Icons/IconWireframe';

const IconTypes = {
    IconAnchor,
    IconArrowLeft,
    IconArrowRight,
    IconBlueprint,
    IconBudget,
    IconBuildingChecklist,
    IconBusinessDeal,
    IconCalendar,
    IconCommercial,
    IconContract,
    IconConversion,
    IconCross,
    IconContractApproved,
    IconEarthHeart,
    IconExtension,
    IconHelp,
    IconInfo,
    IconKitchen,
    IconLeaf,
    IconMail,
    IconMapSearch,
    IconMenu,
    IconNewBuild,
    IconNotebook,
    IconPencils,
    IconPhone,
    IconPlans,
    IconPlay,
    IconQuestionPin,
    IconQuote,
    IconRealEstate,
    IconRenovation,
    IconRibbon,
    IconSignpost,
    IconStairs,
    IconTaskList,
    IconWarning,
    IconWireframe,
};

export default IconTypes;
