import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconRealEstate = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M32.8802 32.2266V46.2266C32.8802 46.4918 32.7749 46.7462 32.5874 46.9337C32.3998 47.1212 32.1455 47.2266 31.8802 47.2266H12.8802V25.2266C12.8802 24.6962 13.091 24.1874 13.466 23.8124C13.8411 23.4373 14.3498 23.2266 14.8802 23.2266H15.8802" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8802 47.2266V42.2266C19.8802 41.9614 19.9856 41.707 20.1731 41.5195C20.3607 41.3319 20.615 41.2266 20.8802 41.2266H24.8802C25.1455 41.2266 25.3998 41.3319 25.5874 41.5195C25.7749 41.707 25.8802 41.9614 25.8802 42.2266V47.2266" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.88025 11.2266H0.880249" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.8802 17.2266H0.880249" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.8802 35.2266H26.8802" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.8802 7.22659V1.22659" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8802 47.2266H1.88025C1.61503 47.2266 1.36068 47.1212 1.17314 46.9337C0.985606 46.7462 0.880249 46.4918 0.880249 46.2266V2.76659C0.880528 2.59927 0.922786 2.43469 1.00315 2.28794C1.08352 2.14118 1.19942 2.01694 1.34025 1.92659C1.48438 1.84781 1.646 1.80653 1.81025 1.80653C1.9745 1.80653 2.13612 1.84781 2.28025 1.92659L16.8402 8.32659" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.5202 1.22659C31.782 1.23425 30.0625 1.58725 28.4619 2.2651C26.8612 2.94295 25.4112 3.93212 24.1961 5.17515C22.9809 6.41818 22.0249 7.89029 21.3836 9.50595C20.7423 11.1216 20.4284 12.8486 20.4602 14.5866C20.365 17.0415 20.961 19.4738 22.1802 21.6066L18.9402 28.4466C18.8921 28.5474 18.8764 28.6606 18.8953 28.7706C18.9142 28.8807 18.9667 28.9822 19.0457 29.0611C19.1246 29.1401 19.2261 29.1926 19.3362 29.2115C19.4463 29.2304 19.5595 29.2147 19.6602 29.1666L26.5002 25.9266C28.2572 27.021 30.2445 27.6921 32.3053 27.8871C34.3661 28.0821 36.444 27.7955 38.375 27.0501C40.3061 26.3046 42.0376 25.1206 43.4328 23.5915C44.828 22.0624 45.8488 20.23 46.4147 18.2389C46.9806 16.2478 47.0761 14.1524 46.6937 12.1181C46.3112 10.0837 45.4613 8.16611 44.2109 6.51644C42.9606 4.86678 41.344 3.53022 39.4887 2.61221C37.6334 1.6942 35.5902 1.21985 33.5202 1.22659V1.22659Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.1002 9.84659H32.7802C32.5176 9.79537 32.2474 9.79639 31.9852 9.84958C31.7229 9.90278 31.4737 10.0071 31.2518 10.1566C30.8036 10.4585 30.4937 10.9262 30.3902 11.4566C30.2868 11.987 30.3983 12.5368 30.7002 12.985C31.0022 13.4332 31.4698 13.7432 32.0002 13.8466L35.2002 15.1266C35.7307 15.2274 36.1993 15.5347 36.5031 15.9811C36.8069 16.4274 36.921 16.9762 36.8202 17.5066C36.7194 18.037 36.4121 18.5057 35.9657 18.8095C35.5194 19.1133 34.9707 19.2274 34.4402 19.1266H30.1002" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.6002 9.84659V7.50659" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.6002 21.5066V19.1666" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconRealEstate;
