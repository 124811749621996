import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconNewBuild = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M3 21V46C3 46.2652 3.10536 46.5196 3.29289 46.7071C3.48043 46.8946 3.73478 47 4 47H44C44.2652 47 44.5196 46.8946 44.7071 46.7071C44.8946 46.5196 45 46.2652 45 46V21" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.054 21C15.3009 21.0016 15.5397 20.9117 15.7243 20.7478C15.9089 20.5838 16.0264 20.3574 16.054 20.112C16.2695 18.1536 17.2001 16.3436 18.6675 15.0288C20.1349 13.7141 22.0358 12.987 24.006 12.987C25.9762 12.987 27.8772 13.7141 29.3445 15.0288C30.8119 16.3436 31.7425 18.1536 31.958 20.112C31.9857 20.3574 32.1031 20.5838 32.2877 20.7478C32.4724 20.9117 32.7111 21.0016 32.958 21H45.534C45.6977 21.0003 45.8588 20.9604 46.0035 20.8839C46.1481 20.8074 46.2717 20.6965 46.3636 20.5611C46.4554 20.4257 46.5125 20.2697 46.53 20.1071C46.5476 19.9444 46.5249 19.7799 46.464 19.628L39.264 1.628C39.1889 1.4404 39.0586 1.27997 38.8905 1.16786C38.7223 1.05575 38.5241 0.997228 38.322 1H9.67803C9.47798 0.999649 9.28242 1.0593 9.11663 1.17126C8.95084 1.28321 8.82244 1.44231 8.74803 1.628L1.54803 19.628C1.48716 19.7799 1.46449 19.9444 1.48201 20.1071C1.49952 20.2697 1.55669 20.4257 1.6485 20.5611C1.74031 20.6965 1.86395 20.8074 2.00858 20.8839C2.15321 20.9604 2.3144 21.0003 2.47803 21H15.054Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27 22C27 21.2044 26.6839 20.4413 26.1213 19.8787C25.5587 19.3161 24.7956 19 24 19C23.2044 19 22.4413 19.3161 21.8787 19.8787C21.3161 20.4413 21 21.2044 21 22V28C21 28.2652 21.1054 28.5196 21.2929 28.7071C21.4804 28.8946 21.7348 29 22 29H26C26.2652 29 26.5196 28.8946 26.7071 28.7071C26.8946 28.5196 27 28.2652 27 28V22Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 47V40C21 39.2044 21.3161 38.4413 21.8787 37.8787C22.4413 37.3161 23.2044 37 24 37C24.7956 37 25.5587 37.3161 26.1213 37.8787C26.6839 38.4413 27 39.2044 27 40V47" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 35H34C33.4477 35 33 35.4477 33 36V40C33 40.5523 33.4477 41 34 41H40C40.5523 41 41 40.5523 41 40V36C41 35.4477 40.5523 35 40 35Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 25H34C33.4477 25 33 25.4477 33 26V30C33 30.5523 33.4477 31 34 31H40C40.5523 31 41 30.5523 41 30V26C41 25.4477 40.5523 25 40 25Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 35H8C7.44772 35 7 35.4477 7 36V40C7 40.5523 7.44772 41 8 41H14C14.5523 41 15 40.5523 15 40V36C15 35.4477 14.5523 35 14 35Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 25H8C7.44772 25 7 25.4477 7 26V30C7 30.5523 7.44772 31 8 31H14C14.5523 31 15 30.5523 15 30V26C15 25.4477 14.5523 25 14 25Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconNewBuild;
