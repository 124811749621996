import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconBusinessDeal = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M20.3597 46.8548C19.482 45.3348 18.2196 44.0725 16.6995 43.1949C15.1793 42.3173 13.455 41.8553 11.6997 41.8553C9.94439 41.8553 8.22003 42.3173 6.69989 43.1949C5.17975 44.0725 3.91739 45.3348 3.03967 46.8548" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.51965 30.8148C7.45186 31.7766 8.56778 32.5412 9.80116 33.0634C11.0345 33.5857 12.3603 33.8548 13.6997 33.8548C15.0752 33.8595 16.4369 33.5804 17.6997 33.0348" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6996 39.8548C15.0134 39.8548 17.6996 37.1685 17.6996 33.8548C17.6996 30.5411 15.0134 27.8548 11.6996 27.8548C8.38594 27.8548 5.69965 30.5411 5.69965 33.8548C5.69965 37.1685 8.38594 39.8548 11.6996 39.8548Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.0397 46.8548C27.9174 45.3348 29.1798 44.0725 30.6999 43.1949C32.22 42.3173 33.9444 41.8553 35.6997 41.8553C37.455 41.8553 39.1793 42.3173 40.6995 43.1949C42.2196 44.0725 43.482 45.3348 44.3597 46.8548" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.8796 30.8148C39.9474 31.7766 38.8315 32.5412 37.5981 33.0634C36.3648 33.5857 35.039 33.8548 33.6996 33.8548C32.3241 33.8595 30.9624 33.5804 29.6996 33.0348" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.6996 39.8548C39.0134 39.8548 41.6996 37.1685 41.6996 33.8548C41.6996 30.5411 39.0134 27.8548 35.6996 27.8548C32.3859 27.8548 29.6996 30.5411 29.6996 33.8548C29.6996 37.1685 32.3859 39.8548 35.6996 39.8548Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.6996 0.854828C38.2301 0.854828 38.7388 1.06554 39.1139 1.44061C39.4889 1.81569 39.6996 2.32439 39.6996 2.85483V20.8548C39.6996 21.3853 39.4889 21.894 39.1139 22.269C38.7388 22.6441 38.2301 22.8548 37.6996 22.8548H29.3596L23.3596 28.8548V22.8548H9.69965C9.16921 22.8548 8.66051 22.6441 8.28543 22.269C7.91036 21.894 7.69965 21.3853 7.69965 20.8548V2.85483C7.69965 2.32439 7.91036 1.81569 8.28543 1.44061C8.66051 1.06554 9.16921 0.854828 9.69965 0.854828H37.6996Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.6996 15.8548V17.8548" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.6996 5.85483V7.85483" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.6996 15.8548H25.6996C26.2301 15.8548 26.7388 15.6441 27.1139 15.269C27.4889 14.894 27.6996 14.3853 27.6996 13.8548C27.6996 13.3244 27.4889 12.8157 27.1139 12.4406C26.7388 12.0655 26.2301 11.8548 25.6996 11.8548H21.6996C21.1692 11.8548 20.6605 11.6441 20.2854 11.269C19.9104 10.894 19.6996 10.3853 19.6996 9.85483C19.6996 9.32439 19.9104 8.81569 20.2854 8.44061C20.6605 8.06554 21.1692 7.85483 21.6996 7.85483H27.6996" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconBusinessDeal;
