import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconConversion = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M1 42.954H47" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 18.954V42.954" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43 42.954V18.954" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47 15.61C46.9999 15.4155 46.9431 15.2253 46.8365 15.0626C46.7299 14.8999 46.5783 14.7718 46.4 14.694L24.4 5.128C24.2738 5.07294 24.1377 5.04451 24 5.04451C23.8623 5.04451 23.7262 5.07294 23.6 5.128L1.6 14.694C1.42175 14.7718 1.27005 14.8999 1.16348 15.0626C1.05691 15.2253 1.0001 15.4155 1 15.61V17.954C1 18.2192 1.10536 18.4736 1.29289 18.6611C1.48043 18.8486 1.73478 18.954 2 18.954H46C46.2652 18.954 46.5196 18.8486 46.7071 18.6611C46.8946 18.4736 47 18.2192 47 17.954V15.61Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 42.954C9.73478 42.954 9.48043 42.8486 9.29289 42.6611C9.10536 42.4736 9 42.2192 9 41.954V23.954C9 23.6888 9.10536 23.4344 9.29289 23.2469C9.48043 23.0594 9.73478 22.954 10 22.954H38C38.2652 22.954 38.5196 23.0594 38.7071 23.2469C38.8946 23.4344 39 23.6888 39 23.954V41.954C39 42.2192 38.8946 42.4736 38.7071 42.6611C38.5196 42.8486 38.2652 42.954 38 42.954" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 28.954H35" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 32.954H35" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 36.954H35" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconConversion;
