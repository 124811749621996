import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconInfo = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2974 36.7025 1 24 1C11.2974 1 1 11.2974 1 24C1 36.7025 11.2974 47 24 47Z" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="24" cy="13" r="1" strokeWidth="1.5" />
        <path d="M29 34.01H26C25.4696 34.01 24.9608 33.7992 24.5858 33.4242C24.2108 33.0492 24 32.5404 24 32.01V19.01C24 18.7448 23.8946 18.4904 23.7072 18.3029C23.5196 18.1154 23.2652 18.01 23 18.01H20" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconInfo;
