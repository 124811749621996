import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconPlans = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M26.7705 35.4665V30.4665C26.7705 29.6708 27.0866 28.9078 27.6492 28.3452C28.2118 27.7825 28.9749 27.4665 29.7705 27.4665C30.5662 27.4665 31.3292 27.7825 31.8918 28.3452C32.4544 28.9078 32.7705 29.6708 32.7705 30.4665V35.4665" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.7705 23.8305V34.4665C22.7705 34.7317 22.8759 34.986 23.0634 35.1736C23.2509 35.3611 23.5053 35.4665 23.7705 35.4665H35.7705C36.0357 35.4665 36.2901 35.3611 36.4776 35.1736C36.6652 34.986 36.7705 34.7317 36.7705 34.4665V23.8305" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.7705 27.4665L29.0985 18.0785C29.2825 17.9115 29.5221 17.819 29.7705 17.819C30.019 17.819 30.2585 17.9115 30.4425 18.0785L40.7705 27.4665" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.770508 40.4665C0.770508 42.323 1.50801 44.1035 2.82076 45.4162C4.13351 46.729 5.91399 47.4665 7.77051 47.4665C9.62702 47.4665 11.4075 46.729 12.7203 45.4162C14.033 44.1035 14.7705 42.323 14.7705 40.4665C14.7705 38.61 14.033 36.8295 12.7203 35.5167C11.4075 34.204 9.62702 33.4665 7.77051 33.4665C5.91399 33.4665 4.13351 34.204 2.82076 35.5167C1.50801 36.8295 0.770508 38.61 0.770508 40.4665V40.4665Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.77051 40.4665C4.77051 41.2621 5.08658 42.0252 5.64919 42.5878C6.2118 43.1504 6.97486 43.4665 7.77051 43.4665C8.56616 43.4665 9.32922 43.1504 9.89183 42.5878C10.4544 42.0252 10.7705 41.2621 10.7705 40.4665C10.7705 39.6708 10.4544 38.9078 9.89183 38.3452C9.32922 37.7825 8.56616 37.4665 7.77051 37.4665C6.97486 37.4665 6.2118 37.7825 5.64919 38.3452C5.08658 38.9078 4.77051 39.6708 4.77051 40.4665Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.770508 40.4665V8.46648C0.770508 6.60996 1.50801 4.82948 2.82076 3.51673C4.13351 2.20397 5.91399 1.46648 7.77051 1.46648C9.62702 1.46648 11.4075 2.20397 12.7203 3.51673C14.033 4.82948 14.7705 6.60996 14.7705 8.46648V40.4665" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.77051 47.4665H44.7705C45.3009 47.4665 45.8097 47.2558 46.1847 46.8807C46.5598 46.5056 46.7705 45.9969 46.7705 45.4665V9.46648C46.7705 8.93604 46.5598 8.42734 46.1847 8.05226C45.8097 7.67719 45.3009 7.46648 44.7705 7.46648H19.7705" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconPlans;
