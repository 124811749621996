import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconHelp = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2974 36.7025 1 24 1C11.2975 1 1 11.2974 1 24C1 36.7025 11.2975 47 24 47Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 18.0098C17.0002 16.748 17.3415 15.5095 17.9879 14.4256C18.6343 13.3417 19.5618 12.4527 20.672 11.8528C21.7822 11.2528 23.034 10.9641 24.2947 11.0172C25.5556 11.0703 26.7788 11.4634 27.8346 12.1547C28.8902 12.846 29.7396 13.8098 30.2926 14.9443C30.8453 16.0787 31.0812 17.3415 30.9753 18.599C30.8694 19.8564 30.4253 21.0621 29.6903 22.0878C28.9553 23.1138 27.9566 23.9218 26.8 24.4265C25.9677 24.7898 25.2595 25.3879 24.7623 26.1476C24.2648 26.9073 24 27.7956 24 28.7035V29.6649" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="24" cy="35" r="1" />
    </BaseIcon>
);

export default IconHelp;
