import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconContractApproved = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M46.9943 43.7501L44.9943 39.8821V30.7501C45.0783 27.1941 40.8323 24.0241 36.9943 20.7501" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.0323 34.7501L34.9303 29.5361C34.7028 29.2993 34.4307 29.1098 34.1296 28.9786C33.8286 28.8474 33.5046 28.777 33.1762 28.7716C32.8479 28.7661 32.5217 28.8256 32.2165 28.9467C31.9112 29.0678 31.633 29.2481 31.3977 29.4772C31.1624 29.7063 30.9747 29.9796 30.8455 30.2815C30.7163 30.5834 30.6481 30.9079 30.6448 31.2363C30.6415 31.5646 30.7032 31.8904 30.8264 32.1948C30.9495 32.4993 31.1316 32.7763 31.3623 33.0101L36.9943 38.7501V41.7501C37.3005 43.5392 37.9822 45.2433 38.9943 46.7501" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.994263 3.75009L2.99426 7.61809V16.7501C2.91026 20.3061 7.15626 23.4761 10.9943 26.7501" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.95624 12.7501L13.0582 17.9641C13.2858 18.2009 13.5579 18.3904 13.8589 18.5216C14.1599 18.6528 14.4839 18.7231 14.8123 18.7286C15.1406 18.7341 15.4668 18.6746 15.772 18.5535C16.0773 18.4324 16.3556 18.2521 16.5908 18.023C16.8261 17.7939 17.0138 17.5206 17.143 17.2187C17.2722 16.9168 17.3404 16.5923 17.3437 16.2639C17.347 15.9355 17.2853 15.6098 17.1621 15.3053C17.039 15.0009 16.8569 14.7238 16.6262 14.4901L10.9942 8.75009V5.75009C10.688 3.96094 10.0064 2.2569 8.99424 0.750092" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9943 15.8541V45.7501C10.9943 46.0153 11.0996 46.2697 11.2872 46.4572C11.4747 46.6447 11.729 46.7501 11.9943 46.7501H33.9943" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.9943 4.75009H14.9943" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36.9943 31.6461V19.7501" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.9943 30.7501H14.9943" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.9943 24.7501H14.9943" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.9943 36.7501H14.9943" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.9943 8.75009C40.9943 10.3323 40.5251 11.8791 39.646 13.1947C38.767 14.5102 37.5175 15.5356 36.0557 16.1411C34.5939 16.7466 32.9854 16.9051 31.4335 16.5964C29.8817 16.2877 28.4562 15.5258 27.3374 14.4069C26.2186 13.2881 25.4567 11.8627 25.148 10.3108C24.8393 8.75897 24.9977 7.15043 25.6032 5.68862C26.2087 4.22681 27.2341 2.97739 28.5497 2.09833C29.8653 1.21928 31.412 0.750092 32.9943 0.750092" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.9943 2.75009L33.7023 10.0421C33.6094 10.1352 33.499 10.2091 33.3775 10.2595C33.256 10.3099 33.1258 10.3359 32.9943 10.3359C32.8627 10.3359 32.7325 10.3099 32.611 10.2595C32.4895 10.2091 32.3792 10.1352 32.2863 10.0421L29.9943 7.75009" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconContractApproved;
