import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconRibbon = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M8.47602 28.832L2.13402 39.782C2.03862 39.9463 1.99201 40.1344 1.99965 40.3243C2.00728 40.5141 2.06884 40.6978 2.17713 40.854C2.28541 41.0101 2.43592 41.1321 2.61106 41.2058C2.78619 41.2795 2.97869 41.3018 3.16602 41.27L8.76602 40.322L10.716 45.68C10.7805 45.8581 10.8943 46.0142 11.0441 46.1302C11.1939 46.2461 11.3736 46.3171 11.5622 46.3349C11.7508 46.3528 11.9406 46.3166 12.1094 46.2308C12.2783 46.1449 12.4193 46.0129 12.516 45.85L17.962 36.636" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M39.524 28.832L45.866 39.782C45.9614 39.9463 46.008 40.1344 46.0004 40.3243C45.9927 40.5141 45.9312 40.6978 45.8229 40.854C45.7146 41.0101 45.5641 41.1321 45.389 41.2058C45.2138 41.2795 45.0213 41.3018 44.834 41.27L39.234 40.322L37.284 45.68C37.2195 45.8581 37.1057 46.0142 36.9559 46.1302C36.8061 46.2461 36.6264 46.3171 36.4378 46.3349C36.2492 46.3528 36.0595 46.3166 35.8906 46.2308C35.7217 46.1449 35.5807 46.0129 35.484 45.85L30.038 36.636" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.96799 19.66C5.96799 24.4339 7.86441 29.0123 11.2401 32.3879C14.6157 35.7636 19.1941 37.66 23.968 37.66C28.7419 37.66 33.3203 35.7636 36.6959 32.3879C40.0716 29.0123 41.968 24.4339 41.968 19.66C41.968 14.8861 40.0716 10.3077 36.6959 6.93208C33.3203 3.55643 28.7419 1.66 23.968 1.66C19.1941 1.66 14.6157 3.55643 11.2401 6.93208C7.86441 10.3077 5.96799 14.8861 5.96799 19.66V19.66Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.144 10.378L27.708 15.666H32.708C32.9581 15.6597 33.2042 15.7302 33.4132 15.8678C33.6221 16.0055 33.7839 16.2038 33.8769 16.4361C33.9699 16.6684 33.9895 16.9236 33.9332 17.1674C33.8769 17.4112 33.7474 17.632 33.562 17.8L29.23 22.07L31.63 27.592C31.7354 27.8446 31.7589 28.1238 31.6973 28.3905C31.6356 28.6571 31.4918 28.8977 31.2862 29.0784C31.0806 29.259 30.8236 29.3706 30.5512 29.3974C30.2788 29.4243 30.0049 29.365 29.768 29.228L23.968 25.96L18.168 29.228C17.9311 29.365 17.6572 29.4243 17.3848 29.3974C17.1124 29.3706 16.8554 29.259 16.6498 29.0784C16.4442 28.8977 16.3004 28.6571 16.2387 28.3905C16.1771 28.1238 16.2006 27.8446 16.306 27.592L18.706 22.07L14.374 17.8C14.1856 17.6326 14.0535 17.411 13.9958 17.1657C13.938 16.9204 13.9574 16.6632 14.0513 16.4293C14.1451 16.1954 14.3089 15.9962 14.5202 15.8589C14.7316 15.7215 14.9801 15.6528 15.232 15.662H20.232L22.8 10.378C22.9118 10.1638 23.0801 9.98432 23.2868 9.85909C23.4934 9.73385 23.7304 9.66763 23.972 9.66763C24.2136 9.66763 24.4506 9.73385 24.6572 9.85909C24.8639 9.98432 25.0322 10.1638 25.144 10.378V10.378Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconRibbon;
