import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconStairs = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M26 1H22V47M26 1V47H22M26 1H47V3C47 3.53043 46.7893 4.03914 46.4142 4.41421C46.0391 4.78929 45.5304 5 45 5H26M22 47H3C2.46957 47 1.96086 46.7893 1.58579 46.4142C1.21071 46.0391 1 45.5304 1 45M1 45V43H18M1 45V26C3 22 13 13 22 13M18 39H7C6.46957 39 5.96086 38.7893 5.58579 38.4142C5.21071 38.0391 5 37.5304 5 37M5 37V35H18M5 37V21.16M18 27H9V29M9 29C9 29.5304 9.21071 30.0391 9.58579 30.4142C9.96086 30.7893 10.4696 31 11 31H18M9 29V17.92M26 9H44M44 9V11C44 11.5304 43.7893 12.0391 43.4142 12.4142C43.0391 12.7893 42.5304 13 42 13H26M44 9V5M26 17H39M39 17V19C39 19.5304 38.7893 20.0391 38.4142 20.4142C38.0391 20.7893 37.5304 21 37 21H26M39 17V13" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconStairs;
