import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconBudget = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M5.04724 4.83823H43.0472V38.8382H5.04724V4.83823Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.0472 44.8382C22.0472 45.3687 22.258 45.8774 22.633 46.2524C23.0081 46.6275 23.5168 46.8382 24.0472 46.8382C24.5777 46.8382 25.0864 46.6275 25.4615 46.2524C25.8365 45.8774 26.0472 45.3687 26.0472 44.8382C26.0472 44.3078 25.8365 43.7991 25.4615 43.424C25.0864 43.0489 24.5777 42.8382 24.0472 42.8382C23.5168 42.8382 23.0081 43.0489 22.633 43.424C22.258 43.7991 22.0472 44.3078 22.0472 44.8382Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.0472 38.8382V42.8382" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.0472 11.8382V9.83823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.0393 11.8382H13.9813C13.3581 11.8387 12.7546 12.0559 12.2741 12.4527C11.7936 12.8495 11.4662 13.4011 11.3479 14.0129C11.2297 14.6247 11.328 15.2586 11.626 15.8059C11.924 16.3531 12.4032 16.7796 12.9813 17.0122L17.1093 18.6642C17.687 18.8967 18.166 19.3229 18.4641 19.8697C18.7621 20.4166 18.8607 21.05 18.743 21.6616C18.6253 22.2732 18.2985 22.8248 17.8188 23.2219C17.339 23.619 16.7361 23.8369 16.1133 23.8382H12.0393" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.0472 25.8382V23.8382" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.0392 30.8382V19.8382C35.0392 19.573 34.9339 19.3187 34.7464 19.1311C34.5588 18.9436 34.3045 18.8382 34.0392 18.8382H32.0392C31.774 18.8382 31.5197 18.9436 31.3321 19.1311C31.1446 19.3187 31.0392 19.573 31.0392 19.8382V30.8382" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.0392 30.8382V25.8382C27.0392 25.573 26.9339 25.3187 26.7464 25.1311C26.5588 24.9436 26.3045 24.8382 26.0392 24.8382H24.0392C23.774 24.8382 23.5197 24.9436 23.3321 25.1311C23.1446 25.3187 23.0392 25.573 23.0392 25.8382V30.8382" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.0472 30.8382H38.0472" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.0392 2.83823C47.0392 3.36867 46.8285 3.87737 46.4535 4.25245C46.0784 4.62752 45.5697 4.83823 45.0392 4.83823H3.03925C2.50881 4.83823 2.0001 4.62752 1.62503 4.25245C1.24996 3.87737 1.03925 3.36867 1.03925 2.83823C1.03925 2.3078 1.24996 1.79909 1.62503 1.42402C2.0001 1.04895 2.50881 0.838234 3.03925 0.838234H45.0392C45.5697 0.838234 46.0784 1.04895 46.4535 1.42402C46.8285 1.79909 47.0392 2.3078 47.0392 2.83823Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.04724 38.8382H45.0472" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconBudget;
